import { Box, Skeleton } from '@mui/material';

const DepositWithdrawSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="text" width="100%" height={'40px'} />
      <Skeleton variant="text" width="100%" height={'77px'} />
      <Skeleton variant="text" width="100%" height={'81px'} />
      <Skeleton variant="text" width="100%" height={'81px'} />
      <Skeleton variant="text" width="100%" height={'77px'} />
      <Skeleton variant="text" width="100%" height={'24px'} />
      <Skeleton variant="text" width="100%" height={'69px'} />
      <Skeleton variant="text" width="100%" height={'226px'} />
      <Skeleton variant="text" width="100%" height={'100px'} />
      <Skeleton variant="text" width="100%" height={'66px'} />
      <Skeleton variant="text" width="100%" height={'82px'} />
    </Box>
  );
};

export default DepositWithdrawSkeleton;
